import {Controller} from 'stimulus';

export default class extends Controller {
  static targets = ['passwordToggle'];

  togglePasswordVisibility() {
    const togglePassword = event.currentTarget;
    const passwordInput = $(togglePassword).siblings('div').find('input');

    if (passwordInput) {
      const type = passwordInput.attr('type') === 'password' ? 'text' : 'password';
      passwordInput.attr('type', type);
      togglePassword.children[0].classList.toggle('fa-eye-slash');
    }
  }

}

